import React, { FC } from "react";

interface ImageWithTitleProps {
  src: string;
  alt: string;
  header: string;
  subHeader: string;
  reverse?: boolean;
}

const ImageWithTitle: FC<ImageWithTitleProps> = ({
  src,
  alt,
  header,
  subHeader,
  reverse,
}) => {
  return (
    <div className="row">
      {reverse ? (
        <>
          <div className="col-lg-6 mt-5  justify-content-center text-lg-left text-center">
            <h3 className="f_size_30 l_height45 f_700 mb_20">{header} </h3>
            <p>{subHeader}</p>
          </div>
          <div className="col-lg-6 mb-5  text-center d-flex">
            <img className="use-case-image m-auto" src={src} alt={alt} />
          </div>
        </>
      ) : (
        <>
          <div className="col-lg-6 mb-5 order-2 order-lg-1  text-center d-flex">
            <img className="use-case-image m-auto" src={src} alt={alt} />
          </div>
          <div className="col-lg-6 mt-5  order-1 order-lg-2 justify-content-center text-lg-left text-center">
            <h3 className="f_size_30 l_height45 f_700 mb_20">{header}</h3>
            <p>{subHeader}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageWithTitle;
